import baseApiSlice from 'api/baseApiSlice';

const SHARE_BADGE_API_URL = '/api/badges';

const badgeSharingApiWithTags = baseApiSlice.enhanceEndpoints({
  addTagTypes: ['sharing'],
});

const badgeSharingApiSlice = badgeSharingApiWithTags.injectEndpoints({
  endpoints: builder => ({
    getSharedBadge: builder.query({
      query: slug => ({
        url: `${SHARE_BADGE_API_URL}/shared_view/${slug}/`,
      }),
    }),
    userSharedBadge: builder.mutation({
      query: ({ data, signal }: any) => ({
        url: `${SHARE_BADGE_API_URL}/share_badge/`,
        method: 'POST',
        data,
      }),
    }),
  }),
});

export const { useGetSharedBadgeQuery, useUserSharedBadgeMutation } = badgeSharingApiSlice;

export default badgeSharingApiSlice;

import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { useState, useCallback, FunctionComponent } from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';

import { BadgeSharingWrapper as SharingWrapper } from 'pages/Dashboard/components/Achievements/style';
import CopyToClipboard from 'react-copy-to-clipboard';
import { StyledLabel as FormControlLabel } from 'elements/FormControlLabel/style';
import TextField from 'elements/Form/TextField';
import Button from 'elements/Button';
import { ReactComponent as TwitterXIcon } from 'assets/images/icons/TwitterX.svg';

const commonShareButtonStyles = {
  display: 'inline-flex',
  alignItems: 'center',
  gap: '0.625rem',
  width: '8.125rem',
  borderRadius: 8,
};

const iconSize = 40;
const commonIconStyles = {
  borderRadius: 8,
};

const CopyToClipboardButton = ({ onCopy, sharingUrl, copied }: any) => (
  <CopyToClipboard onCopy={onCopy} text={sharingUrl}>
    <Tooltip title={copied ? 'Copied to clibboard!' : 'Copy'} arrow>
      <Button variant='contained' className='btn-share' onClick={onCopy} ariaLive="polite">
        {copied ? 'Copied URL' : 'Copy URL'}
      </Button>
    </Tooltip>
  </CopyToClipboard>
);

const CertificateSharing: FunctionComponent<{ sharingUrl: string, certificate: any, handleCertificateSharingStatus: any }> = ({
  sharingUrl,
  certificate,
  handleCertificateSharingStatus
}) => {
  const [copied, setCopied] = useState(false);
  const onCopy = useCallback(() => {
    setCopied(true);
    navigator.clipboard.writeText(sharingUrl);
  }, [sharingUrl]);

  return (
    <SharingWrapper>
      <Typography textAlign='center' fontWeight={600}>
        Share on
      </Typography>

      <Stack
        direction='row'
        gap={2.5}
        alignItems='center'
        justifyContent='center'
        flexWrap='wrap'
        mt={2.5}
      >
        <LinkedinShareButton
          style={{ ...commonShareButtonStyles, backgroundColor: '#026CA9' }}
          url={sharingUrl}
          // title={badgeName}
          // summary={socialMediaDescription}
          onClick={() => handleCertificateSharingStatus('linkedin', certificate)}
        >
          <LinkedinIcon size={iconSize} style={commonIconStyles} />
          <Typography variant='h5' color='white'>
            Linkedin
          </Typography>
        </LinkedinShareButton>

        {/* <TwitterShareButton
          style={{ ...commonShareButtonStyles, backgroundColor: '#1191C9' }}
          url={sharingUrl}
          // title={`${badgeName} | ${socialMediaDescription}`}
        >
          <TwitterIcon size={iconSize} style={commonIconStyles} />
          <Typography variant='h5' color='white'>
            Twitter
          </Typography>
        </TwitterShareButton> */}

        <FacebookShareButton
          style={{ ...commonShareButtonStyles, backgroundColor: '#2F4D8B' }}
          url={sharingUrl}
          onClick={() => handleCertificateSharingStatus('facebook', certificate)}
          // quote={socialMediaDescription} // facebook does not support this anymore
        >
          <FacebookIcon size={iconSize} style={commonIconStyles} />
          <Typography variant='h5' color='white'>
            Facebook
          </Typography>
        </FacebookShareButton>

        <div className='social-link-holder'>
          <FormControlLabel
            label=''
            labelPlacement='top'
            control={
              <TextField
                fullWidth
                className='has-data'
                disabled={true}
                id='selectClub'
                value={sharingUrl}
                readOnly
              />
            }
          />
          <CopyToClipboardButton
            onCopy={onCopy}
            text={sharingUrl}
            copied={copied}
          />
        </div>
      </Stack>
    </SharingWrapper>
  );
};

export default CertificateSharing;
